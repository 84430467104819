import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Bio from './components/Bio';

const App = () => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Bio />} />
      </Routes>
    </Router>
  );
};

export default App;
