import React from 'react';
import Links from './parts/Links';
import { Helmet } from 'react-helmet'; 
import bgImage from '../assets/images/backgroundhero.jpg';

const Bio = () => {
  return (
    <>
      <Helmet>
        <title>Canto Bar de Parrilla</title>
        <meta name="description" content="A autêntica parrilla uruguaia e argentina em Curitiba." />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Canto Bar de Parrilla" />
        <meta property="og:description" content="A verdadeira experiência da parrilla em Curitiba." />
        
        {/* URL Canônica */}
        <link rel="canonical" href="https://www.cantobardeparrilla.com.br/bio" />
      </Helmet>
      <div className="min-h-screen bg-stone-900 relative overflow-hidden">
        {/* Background Image com blur */}
        <div 
          className="absolute inset-0 z-0"
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            filter: 'blur(8px) brightness(0.3)',
            transform: 'scale(1.1)', // Evita bordas brancas do blur
          }}
        />

        {/* Efeito de fogo */}
        <div className="absolute inset-0 z-1 opacity-50">
          <div className="fire-overlay"></div>
          <div className="fire-overlay fire-overlay-2"></div>
          <div className="fire-overlay fire-overlay-3"></div>
        </div>
        
        {/* Conteúdo principal */}
        <div className="relative z-10 min-h-screen bg-stone-900/50">
          <Links />
        </div>
      </div>
    </>
  );
};

export default Bio;
